@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes flash {
  10% {
    background-color: orange;
  }
}
.flash {
  animation: flash 2s infinite linear;
}

@import url("https://fonts.googleapis.com/css?family=Fjalla+One&display=swap");

.background-active-sessions {
  min-height: 100%;
  background: url('data:image/svg+xml;utf8,<svg style="transform:rotate(45deg);font-family:Fjalla+One;" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 100 100"><text x="0" y="50"  fill="green">Active Session</text></svg>')
    0 0/50px 50px; /* update the 50px 50px to control the size */
}
