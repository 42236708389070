@import url(https://fonts.googleapis.com/css?family=Fjalla+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.pac-container {
  z-index: 2000 !important;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.rotate {
  -webkit-animation: rotation 3s infinite linear;
          animation: rotation 3s infinite linear;
}

@-webkit-keyframes flash {
  10% {
    background-color: orange;
  }
}

@keyframes flash {
  10% {
    background-color: orange;
  }
}
.flash {
  -webkit-animation: flash 2s infinite linear;
          animation: flash 2s infinite linear;
}

.background-active-sessions {
  min-height: 100%;
  background: url('data:image/svg+xml;utf8,<svg style="transform:rotate(45deg);font-family:Fjalla+One;" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 100 100"><text x="0" y="50"  fill="green">Active Session</text></svg>')
    0 0/50px 50px; /* update the 50px 50px to control the size */
}

